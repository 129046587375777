import { initializeHybridly } from 'virtual:hybridly/config'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createHead } from '@unhead/vue'
import sentry from './sentry'
import toast from './toast'
import vapor from 'laravel-vapor';

window.Vapor = vapor;

initializeHybridly({
    viewTransition: false,
    enhanceVue: (vue) => {
        const head = createHead()
        head.push({titleTemplate: (title) => title ? `${title} - FenceFlow` : 'FenceFlow'})

        vue
            .use(sentry())
            .use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyDDwz37oepQdsVORnDbaHab5RO6kYORjFU',
                libraries: 'places',
            },
        })
            .use(head)
    },
    plugins: [toast()],
})
